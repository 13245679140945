import React, { useEffect } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import uuid from 'react-uuid'
const lang = localStorage.getItem('language') || 'fi'

export const Dropdown = ({ message, values, update, selected, error, required }) => {
  const id = uuid()


  const onChange = (e) => {
    update(e.target.value)
  }

  useEffect(() => {
    if (!selected) update(values?.[0]?._id)
  }, [])

  useEffect(() => {
    if (!values) return
    for (const value of values) {
      if (value._id === selected) return
    }
    update(values?.[0]?._id)
  }, [values])

  return (
    <div className="fieldset">
      <label htmlFor={id}>
        <FormattedMessage id={message.id}
          defaultMessage={message.default} />{required && " *"}
      </label>
      <select id={id} value={selected} onChange={onChange} className={error ? "inputError" : ""} >
        {(values && values.length > 0) && values.map((v, i) => {
          if (v.message_id) return (<FormattedMessage id={v.message_id}>{(message) => <option key={id + i} value={v._id}>{message}</option>}</FormattedMessage>)
          if (lang === 'fi') return (<option key={id + i} value={v._id}>{v.name}</option>)
          if (lang === 'sv') return (<option key={id + i} value={v._id}>{v.name_sv || v.name}</option>)
          if (lang === 'en') return (<option key={id + i} value={v._id}>{v.name_en || v.name}</option>)
        })}
      </select>
    </div>
  )
}

export const RadioGroup = ({ values, update, error }) => {

  const id = uuid()


  const onChange = (e) => {
    update(e.target.value)
  }

  return (
    <div className="fieldset">
      {(values && values.length > 0) &&
        values.map((v, i) => {
          return (
            <div key={id + i}>
              <label htmlFor={id + i} className="container" >
                <FormattedMessage id={v.message.id}
                  defaultMessage={v.message.default} />
                {v.message.value &&
                  <>{v.message.value}</>
                }
                <input
                  type="radio"
                  name={id}
                  id={id + i}
                  value={v.value}
                  onChange={onChange}
                  checked={v.checked}
                  disabled={v.disabled} />
                <span className={`checkmark ${error ? "radioError" : ""} ${v.disabled ? "radioDisabled" : ""}`}></span>
              </label><br />
            </div>
          )
        })
      }
    </div>
  )
}

export const Checkbox = ({ message, update, checked, link, time }) => {

  const id = uuid()

  const onChange = (e) => {
    update(e.target.checked)
  }

  return (
    <div className="fieldset">
      <label htmlFor={id} className="checkBoxContainer" >
        <span>
          {(link && link.length > 0) ? (
            <a href={link?.find(l => l.lang === lang)?.location} rel="noreferrer" target="_blank">
              <FormattedMessage id={message.id}
                defaultMessage={message.default} />
            </a>
          ) : (
            <FormattedMessage id={message.id}
              defaultMessage={message.default} />
          )}
          {(time && time[0] && time[1] && !time.includes("undefined")) &&
            <>
              <FormattedDate
                value={new Date(time[0])} />
              -
              <FormattedDate
                value={new Date(time[1])} />
            </>
          }
        </span>
        <input type="checkbox" id={id} onChange={onChange} checked={checked} />
        <span className="checkBoxCheckmark"></span>
      </label>
    </div>
  )
}

export const Amount = ({ inputHook, message, max, disabled }) => {
  return (
    <div className="fieldset">
      <input {...inputHook} min="0" max={max} disabled={(typeof max === "number" && !(max > 0)) || disabled === true} className="input" />
      <label htmlFor={inputHook.id}>
        <FormattedMessage id={message.id}
          defaultMessage={message.default} />
        {typeof max === "number" &&
          <span>
            {max} <FormattedMessage id="universal.left" />
          </span>
        }
      </label>
    </div>
  )
}

export const Input = ({ inputHook, message, max, required, min, length, disabled, error }) => {
  return (
    <div className="fieldset">
      <label htmlFor={inputHook.id} >
        <FormattedMessage id={message.id}
          defaultMessage={message?.default} />{length && ", m"}{required && " *"}
      </label>
      <input {...inputHook} max={max} min={min} disabled={disabled === true} className={`input ${error ? "inputError" : ""}`} />
    </div>
  )
}

export const TextArea = ({ inputHook, message, additionalMessage, additionalClassname }) => {
  return (
    <div className="fieldset">
      <label htmlFor={inputHook.id}>
        <FormattedMessage id={message.id}
          defaultMessage={message?.default} />
      </label>
      <br />
      {additionalMessage &&
        <span className={additionalClassname}>
          <FormattedMessage id={additionalMessage} />
        </span>
      }
      <textarea {...inputHook} />
    </div>
  )
}
